import { useRef, useState, useEffect } from 'react';
import ArrowDownIcon from './ArrowDownIcon';

const Dropdown = (props) => {
  const { value, placeholder, options, onChange, className } = props;

  const selectRef = useRef(null);
  const [openList, setOpenList] = useState(false);

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setOpenList(false);
    }
  };

  const handleClick = () => {
    setOpenList(state => !state);
  };

  const handleSelect = (option) => {
    onChange(option);
    setOpenList(false);
  };

  return (
    <div className={`${className} w-full relative px-3 text-base border-[2px] border-tan-100 rounded-lg`} ref={selectRef}>
      <div onClick={handleClick} className={`${value ? 'form-select-focused' : openList ? 'form-select-focused' : 'form-select'} w-full h-full py-4 pr-7 relative`}>
        <div className='cursor-default select-none text-tan-100 text-base font-medium'>{value?.name}</div>
        <div className="select-label cursor-default text-grayLightActive">{placeholder}</div>
        <ArrowDownIcon color="#FFFCF8" className="absolute duration-200 right-0 top-0 bottom-0 my-auto" />
      </div>
      {openList && <div className='absolute p-1 left-0 shadow-bottom text-green-400 rounded-lg rounded-b-lg bottom-full z-10 w-full py-2 bg-grey-200'>
        <div className='max-h-[300px] overflow-auto'>
          {options && options.map((option, index) => (
            <div
              key={index}
              onClick={() => handleSelect(option)}
              className="px-2 cursor-default hover:bg-green-400 hover:text-tan-100 pt-2 pb-1"
            >
              {option?.name}
            </div>
          ))}
        </div>
      </div>}
    </div>
  )
};

export default Dropdown;
