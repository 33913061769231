const ArrowDownIcon = (props) => {
  const { className, color } = props;
  
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <g clipPath="url(#clip0_185_746)">
        <path d="M16.5 10.0259L12 14.5259L7.5 10.0259" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_185_746">
          <rect width="24" height="24" fill="white" transform="translate(0 0.275879)" />
        </clipPath>
      </defs>
    </svg>
  )
};

export default ArrowDownIcon;