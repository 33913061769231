const StarIcon = (props) => {
  const { color, className } = props;

  return (
    <svg className={`w-[13px] h-[13px] ${className}`} xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
      <path d="M6.43567 0.873852C6.61565 0.375382 7.31642 0.375383 7.49641 0.873853L8.68161 4.15628C8.76023 4.37403 8.9625 4.52186 9.19265 4.5298L12.6621 4.64943C13.1889 4.6676 13.4055 5.33807 12.9898 5.66431L10.2529 7.81259C10.0714 7.9551 9.99411 8.1943 10.0577 8.41696L11.0167 11.7733C11.1624 12.283 10.5954 12.6974 10.1586 12.4005L7.28188 10.4458C7.09104 10.3162 6.84103 10.3162 6.6502 10.4458L3.77349 12.4005C3.33663 12.6974 2.7697 12.283 2.91533 11.7733L3.87434 8.41696C3.93796 8.1943 3.8607 7.9551 3.67914 7.81259L0.942232 5.66431C0.526604 5.33807 0.743154 4.6676 1.27002 4.64943L4.73942 4.5298C4.96958 4.52186 5.17184 4.37403 5.25046 4.15628L6.43567 0.873852Z" fill={color} />
    </svg>
  )
};

export default StarIcon;