import { Link, useLocation, useSearchParams } from "react-router-dom";
import { encode as base64encode } from 'base-64';
import data from '../data/pixels.json';

const MoodLink = (props) => {
  const location = useLocation();
  const _props = { ...props };
  _props.to += _props.to.indexOf('?') === -1 ? location.search : location.search.replace('?', '&');
  if ( _props.to.indexOf('b.hellomood.co/redirect') === -1 ) {
    _props.to += (_props.to.indexOf('?') === -1 ? '?' : '&') + 'hm_agegate=1';
  }

  let [searchParams] = useSearchParams();

  const pixelItem = data.pixels.find(x => window.location.href.startsWith(x.designatedUrl));
  let fbclid = searchParams.get('fbclid');
  if ( ! fbclid ) {
    fbclid = 'empty';
  }

  if ( pixelItem ) {
    _props.to += (_props.to.indexOf('?') === -1 ? '?' : '&') + 'famood=' + base64encode(fbclid + '__' + pixelItem.pixel);
  }

  return (
    <Link {..._props}/>
  )
};

export default MoodLink;