import { Route, Routes } from "react-router-dom";
import AgeGatePage from "pages/AgeGate";

const Router = () => {
  return (
    <Routes>
      <Route path="*" element={ <AgeGatePage /> } />
    </Routes>
  );
};

export default Router;